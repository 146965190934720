import * as React from 'react';
import { SVGProps } from 'react';

const SvgSettingsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="svg-fill"
        d="M2 12c0-.865.11-1.703.316-2.504a3 3 0 0 0 2.674-4.63 9.99 9.99 0 0 1 4.335-2.504 3 3 0 0 0 5.348 0 9.99 9.99 0 0 1 4.335 2.505 3 3 0 0 0 2.675 4.63 10.036 10.036 0 0 1 0 5.007 3 3 0 0 0-2.675 4.629 9.99 9.99 0 0 1-4.335 2.505 3 3 0 0 0-5.348 0 9.99 9.99 0 0 1-4.335-2.505 3 3 0 0 0-2.675-4.63A10.056 10.056 0 0 1 2 12Zm4.804 3c.63 1.09.81 2.346.564 3.524.408.29.842.54 1.297.75A4.994 4.994 0 0 1 12 18c1.26 0 2.438.47 3.335 1.274.455-.21.889-.46 1.297-.75A4.993 4.993 0 0 1 17.196 15a4.993 4.993 0 0 1 2.77-2.25 8.134 8.134 0 0 0 0-1.5A4.993 4.993 0 0 1 17.195 9a4.993 4.993 0 0 1-.564-3.524 7.988 7.988 0 0 0-1.297-.75A4.993 4.993 0 0 1 12 6a4.993 4.993 0 0 1-3.335-1.274 7.99 7.99 0 0 0-1.297.75A4.993 4.993 0 0 1 6.804 9a4.993 4.993 0 0 1-2.77 2.25 8.125 8.125 0 0 0 0 1.5A4.991 4.991 0 0 1 6.804 15ZM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        fill="#1F2937"
      />
    </svg>
  );
};
export default SvgSettingsIcon;
