import * as React from 'react';
import { SVGProps } from 'react';

const SvgEmptyTableIllustration = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={72}
      height={64}
      viewBox="0 0 72 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M22 0h41.667L67.5 4 72 8.5V64H22V0Z" fill="#94A3B8" />
      <path d="M22 0h41.667v8.5H72V64H22V0Z" fill="#475569" />
      <path
        fill="#334155"
        d="M28 20h38v4H28zM28 28h38v4H28zM28 36h38v4H28zM28 44h38v4H28z"
      />
      <path
        d="m16.062 36.598 2.913 3.055L5.688 54.01a2.814 2.814 0 0 1-4.102.03l-.72-.755a2.814 2.814 0 0 1 .225-4.096l14.97-12.592Z"
        fill="#FEAD05"
      />
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={36}
        width={19}
        height={19}
      >
        <path
          d="m16.062 36.598 2.913 3.055L5.688 54.01a2.814 2.814 0 0 1-4.102.03l-.72-.755a2.814 2.814 0 0 1 .225-4.096l14.97-12.592Z"
          fill="#FEAD05"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.39 45.017a20.54 20.54 0 0 1-2.647-2.338 20.538 20.538 0 0 1-2.209-2.754l4.296-3.613c.544.892 1.19 1.74 1.941 2.526a14.9 14.9 0 0 0 2.431 2.059l-3.812 4.12Z"
          fill="#FC680A"
        />
      </g>
      <rect
        x={27.641}
        y={10}
        width={26.736}
        height={26.736}
        rx={13.368}
        transform="rotate(46.358 27.64 10)"
        fill="#755734"
        style={{
          mixBlendMode: 'color',
        }}
      />
      <rect
        x={27.641}
        y={10}
        width={26.736}
        height={26.736}
        rx={13.368}
        transform="rotate(46.358 27.64 10)"
        stroke="#FEAD05"
        strokeWidth={4.221}
      />
    </svg>
  );
};
export default SvgEmptyTableIllustration;
