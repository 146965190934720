import * as React from 'react';
import { SVGProps } from 'react';

const SvgPartnersIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="svg-fill"
        d="M5 9a1 1 0 0 1 1 1 6.97 6.97 0 0 1 4.33 1.5h2.17c1.332 0 2.53.58 3.353 1.5H19a5 5 0 0 1 4.516 2.851c-2.365 3.121-6.194 5.15-10.516 5.15-2.79 0-5.15-.604-7.06-1.659A.998.998 0 0 1 5 20H2a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h3Zm1.001 3L6 17.021l.045.033C7.84 18.314 10.178 19 13 19c3.004 0 5.799-1.156 7.835-3.13l.133-.133-.12-.1a2.994 2.994 0 0 0-1.643-.63L19 15h-2.112c.073.321.112.656.112 1v1H8v-2h6.79l-.034-.079a2.5 2.5 0 0 0-2.092-1.416L12.5 13.5H9.57A4.985 4.985 0 0 0 6.001 12ZM4 11H3v7h1v-7Zm9.646-7.425L14 3.93l.354-.354a2.5 2.5 0 1 1 3.535 3.536L14 11l-3.89-3.89a2.5 2.5 0 1 1 3.536-3.535Zm-2.12 1.415a.5.5 0 0 0-.06.637l.058.07L14 8.17l2.476-2.474a.5.5 0 0 0 .058-.638l-.058-.07a.5.5 0 0 0-.638-.057l-.07.058-1.769 1.768-1.767-1.77-.068-.056a.5.5 0 0 0-.638.06Z"
        fill="#1F2937"
      />
    </svg>
  );
};
export default SvgPartnersIcon;
