import * as React from 'react';
import { SVGProps } from 'react';

const SvgCreditCardIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#a)"
        stroke="#F8FAFC"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M17.5 4.375h-15A.625.625 0 0 0 1.875 5v10c0 .345.28.625.625.625h15c.345 0 .625-.28.625-.625V5a.625.625 0 0 0-.625-.625ZM13.125 13.125h2.5M9.375 13.125h1.25M1.875 7.5h16.25" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgCreditCardIcon;
