import * as React from 'react';
import { SVGProps } from 'react';

const SvgDiamondIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m17.021 8.88-3.467 11.558L22.8 8.88h-5.779ZM6.979 8.88h-5.78l9.247 11.558L6.98 8.88ZM22.588 7.606l-4.604-4.603a.633.633 0 0 0-.447-.186h-2.344l1.796 4.789h5.599ZM7.011 7.606l1.796-4.789H6.463a.633.633 0 0 0-.448.186L1.412 7.606h5.6ZM15.691 8.88H8.31L12 21.183 15.691 8.88ZM8.372 7.606h7.256l-1.796-4.789h-3.664L8.372 7.606Z" />
    </svg>
  );
};
export default SvgDiamondIcon;
