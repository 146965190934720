import * as React from 'react';
import { SVGProps } from 'react';

const SvgInfoIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 15.166A6.666 6.666 0 1 1 8 1.833a6.666 6.666 0 0 1 0 13.333Zm0-1.333A5.333 5.333 0 1 0 8 3.166a5.333 5.333 0 0 0 0 10.667Zm-.667-8.667h1.334V6.5H7.333V5.166Zm0 2.667h1.334v4H7.333v-4Z"
        fill="#3B82F6"
      />
    </svg>
  );
};
export default SvgInfoIcon;
