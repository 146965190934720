import * as React from 'react';
import { SVGProps } from 'react';

const SvgCrystalsSuccess = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={194}
      height={189}
      viewBox="0 0 194 189"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={94}
        cy={94.5}
        r={92}
        fill="#2D3344"
        stroke="#22C55E"
        strokeWidth={4}
      />
      <path
        d="m150.136 41.491-.085-1.279a5.217 5.217 0 0 0-3.771-1.61h-27.582a5.217 5.217 0 0 0-3.771 1.61v1.28l7.125 11.681h22.011l6.073-11.682Z"
        fill="#FACC15"
      />
      <path
        d="M118.698 38.602a5.217 5.217 0 0 0-3.771 1.61v1.28l3.581 5.872 6.171-8.762h-5.981ZM132.489 38.602l-9.912 14.572.618.6h18.207l.999-.6-9.912-14.572Z"
        fill="#FACC15"
      />
      <path
        d="m114.927 40.212-9.902 10.356a3.768 3.768 0 0 0-1.046 2.606l.655.6h16.909l1.033-.6-7.649-12.962Z"
        fill="#FACC15"
      />
      <path
        d="m114.417 53.173 4.439-6.303-3.929-6.658-9.902 10.356a3.768 3.768 0 0 0-1.046 2.605l.655.6h10.095l-.312-.6ZM161 53.173c0-.97-.375-1.903-1.047-2.605l-9.902-10.356-7.65 12.961.761.6H160.5l.5-.6Z"
        fill="#FACC15"
      />
      <path
        d="M103.979 53.173c0 1.09.389 2.142 1.099 2.97l23.347 27.258a5.353 5.353 0 0 0 8.128 0l23.348-27.258a4.568 4.568 0 0 0 1.099-2.97h-57.021Z"
        fill="#FACC15"
      />
      <path
        d="M114.417 53.173h-10.438c0 1.09.389 2.142 1.099 2.97l23.348 27.258a5.342 5.342 0 0 0 2.596 1.662l-16.605-31.89ZM132.49 85.268c.501 0 .994-.07 1.467-.205l8.445-31.89h-19.825l8.445 31.89a5.36 5.36 0 0 0 1.468.205ZM101.715 66.313l-.748-2.192a9.435 9.435 0 0 0-7.332-1.052L45.532 75.912a9.397 9.397 0 0 0-5.822 4.564l.6 2.232 17.905 17.055 38.387-10.249 5.113-23.201Z"
        fill="#FACC15"
      />
      <path
        d="M45.532 75.913a9.396 9.396 0 0 0-5.822 4.564l.6 2.231 9 8.573 6.652-18.153-10.43 2.785ZM69.583 69.491 59.13 99.52l1.359.759L92.242 91.8l1.462-1.512-24.12-20.797Z"
        fill="#FACC15"
      />
      <path
        d="m39.71 80.476-12.413 22.672a6.798 6.798 0 0 0-.603 5.031l1.424.74 29.491-7.873 1.52-1.528-19.42-19.042Z"
        fill="#FACC15"
      />
      <path
        d="m44.9 103.318 4.785-13.06-9.976-9.781-12.412 22.671a6.798 6.798 0 0 0-.603 5.031l1.424.741 17.607-4.701-.826-.901ZM126.139 81.628a6.822 6.822 0 0 0-3.047-4.057l-22.126-13.45-7.263 26.167 1.608.692 30.237-8.074.591-1.278Z"
        fill="#FACC15"
      />
      <path
        d="M26.693 108.179a8.26 8.26 0 0 0 3.31 4.668l53.502 36.665a9.675 9.675 0 0 0 7.962 1.365 9.638 9.638 0 0 0 6.212-5.149l27.935-58.409a8.231 8.231 0 0 0 .524-5.692L26.693 108.18Z"
        fill="#FACC15"
      />
      <path
        d="m44.898 103.319-18.205 4.86a8.266 8.266 0 0 0 3.31 4.669l53.502 36.665a9.674 9.674 0 0 0 5.307 1.689l-43.914-47.883ZM91.469 150.877a9.657 9.657 0 0 0 2.462-1.041l-.227-59.548L59.13 99.52l29.683 51.683a9.64 9.64 0 0 0 2.656-.325Z"
        fill="#FACC15"
      />
      <path
        d="m147.387 43.294-.073-1.1a4.483 4.483 0 0 0-3.241-1.383h-23.707c-1.225 0-2.396.5-3.241 1.384v1.1l6.124 10.04h18.918l5.22-10.04Z"
        fill="#C3DDFF"
      />
      <path
        d="M120.366 40.81c-1.225 0-2.396.5-3.241 1.384v1.1l3.078 5.047 5.304-7.53h-5.141Z"
        fill="#A4CCFF"
      />
      <path
        d="m132.22 40.81-8.52 12.524.531.516h15.649l.859-.516-8.519-12.524ZM117.125 42.195l-8.511 8.9a3.241 3.241 0 0 0-.899 2.24l.563.515h14.534l.888-.515-6.575-11.14Z"
        fill="#DBEAFF"
      />
      <path
        d="m116.687 53.334 3.815-5.418-3.377-5.722-8.511 8.901a3.241 3.241 0 0 0-.899 2.24l.563.515h8.677l-.268-.516Z"
        fill="#C3DDFF"
      />
      <path
        d="M156.723 53.334c0-.833-.322-1.635-.899-2.239l-8.511-8.9-6.575 11.14.654.515h14.902l.429-.516Z"
        fill="#DBEAFF"
      />
      <path
        d="M107.715 53.334c0 .936.335 1.841.945 2.553l20.067 23.427a4.596 4.596 0 0 0 3.492 1.605 4.598 4.598 0 0 0 3.493-1.605l20.067-23.427c.61-.712.945-1.617.945-2.553h-49.009Z"
        fill="#A4CCFF"
      />
      <path
        d="M116.687 53.334h-8.972c0 .936.335 1.841.945 2.553l20.067 23.427a4.593 4.593 0 0 0 2.231 1.429l-14.271-27.408Z"
        fill="#8BB3EA"
      />
      <path
        d="M132.22 80.92a4.6 4.6 0 0 0 1.261-.177l7.258-27.408H123.7l7.258 27.408c.407.116.831.177 1.262.177ZM99.28 70.476l-.676-1.98a8.525 8.525 0 0 0-6.626-.951L48.51 79.15a8.492 8.492 0 0 0-5.261 4.124l.542 2.016 16.18 15.413 34.689-9.262 4.62-20.966Z"
        fill="#C3DDFF"
      />
      <path
        d="M48.51 79.15a8.492 8.492 0 0 0-5.261 4.125l.542 2.016 8.134 7.748 6.01-16.405-9.425 2.517Z"
        fill="#A4CCFF"
      />
      <path
        d="m70.244 73.348-9.446 27.135 1.228.685 28.694-7.661 1.32-1.366-21.796-18.793ZM43.248 83.275l-11.216 20.487a6.143 6.143 0 0 0-.545 4.546l1.287.67 26.65-7.115 1.374-1.38-17.55-17.208Z"
        fill="#DBEAFF"
      />
      <path
        d="m47.938 103.916 4.325-11.802-9.014-8.84-11.217 20.488a6.143 6.143 0 0 0-.545 4.546l1.287.67 15.91-4.248-.746-.814Z"
        fill="#C3DDFF"
      />
      <path
        d="M121.351 84.315a6.168 6.168 0 0 0-2.753-3.665L98.603 68.496 92.04 92.14l1.453.625 27.324-7.295.534-1.156Z"
        fill="#DBEAFF"
      />
      <path
        d="M31.486 108.308a7.468 7.468 0 0 0 2.991 4.219l48.347 33.132a8.74 8.74 0 0 0 7.195 1.233 8.71 8.71 0 0 0 5.613-4.652l25.244-52.782a7.438 7.438 0 0 0 .474-5.143l-89.864 23.993Z"
        fill="#A4CCFF"
      />
      <path
        d="m47.937 103.917-16.45 4.392a7.469 7.469 0 0 0 2.99 4.218l48.347 33.133a8.74 8.74 0 0 0 4.796 1.526l-39.683-43.269Z"
        fill="#8BB3EA"
      />
      <path
        d="M90.02 146.893a8.72 8.72 0 0 0 2.226-.942l-.205-53.81-31.243 8.342 26.823 46.703a8.75 8.75 0 0 0 2.4-.293Z"
        fill="#C3DDFF"
      />
      <circle
        cx={166}
        cy={156.5}
        r={26}
        fill="#22C55E"
        stroke="#2D3344"
        strokeWidth={4}
      />
      <path
        d="m162 163.672-6.586-6.586-2.828 2.828 9.414 9.414 19.414-19.414-2.828-2.828L162 163.672Z"
        fill="#0F172A"
      />
    </svg>
  );
};
export default SvgCrystalsSuccess;
