import * as React from 'react';
import { SVGProps } from 'react';

const SvgCryptoCheckIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#C5C6C7"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10Zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 12 20ZM9 8h6l2.5 3.5L12 17l-5.5-5.5L9 8Zm1.03 2-.92 1.29L12 14.18l2.89-2.89-.92-1.29h-3.94Z" />
    </svg>
  );
};
export default SvgCryptoCheckIcon;
