import * as React from 'react';
import { SVGProps } from 'react';

const SvgBonusNavIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="svg-fill"
        d="m12 18.26-7.053 3.948 1.575-7.928L.587 8.792l8.027-.952L12 .5l3.386 7.34 8.027.952-5.935 5.488 1.575 7.928L12 18.26Zm0-2.292 4.247 2.377-.95-4.773 3.574-3.305-4.833-.573L12 5.275l-2.038 4.42-4.833.572 3.573 3.305-.95 4.773L12 15.968Z"
        fill="#1F2937"
      />
    </svg>
  );
};
export default SvgBonusNavIcon;
